<template lang="html">
  <div class="editor">
    <a-progress v-if="Show" :percent="Number(percentage)" />
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import upload from '@/plugins/upload'

export default {
  name: 'Editorbar',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editor: null,
      isInit: false,
      obsClient: null, // obs服务
      Show: false, // 是否显示进度条
      AverageRate: '0 MB/S', // 上传速度
      percentage: 0 // 百分比
    }
  },
  computed: {

  },
  watch: {
    value (value) {
      if (value && !this.isInit) {
        this.isInit = true
        this.setValue(this.value)
      }
    }
  },
  mounted () {
    this.seteditor()
  },
  methods: {
    clear () {
      this.editor.txt.clear()
    },
    setValue (value) {
      this.editor.txt.html(value)
    },
    seteditor () {
      const _this = this
      // https://www.kancloud.cn/wangfupeng/wangeditor3/338872 配置文档
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      // 配置菜单
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'lineHeight',
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo' // 恢复
      ]
      this.editor.config.uploadImgShowBase64 = false
      this.editor.config.focus = false
      // 设置元素的堆叠顺序。拥有更高堆叠顺序的元素总是会处于堆叠顺序较低的元素的前面。
      this.editor.config.zIndex = 10

      // 用户操作（鼠标点击、键盘打字等）导致的内容变化之后，会自动触发onchange函数执行
      this.editor.config.onchange = html => {
        this.$emit('change', html) // 将内容同步到父组件中
      }
      this.editor.config.onchangeTimeout = 1000
      // 自定义实现上传图片
      this.editor.config.customUploadImg = function (files, insert) {
        // 上传代码返回结果之后，将图片插入到编辑器中
        _this.imageFilesToTarget(files, insert)
      }
      // 自定义实现上传视频
      this.editor.config.uploadVideoAccept = ['mp4']
      this.editor.config.customUploadVideo = (files, insert) => {
        if (files[0].size >= 1024 * 1024 * 500) {
          this.$message.warning('仅支持上传大小为500MB以内视频')
          return
        }

        _this.videoFilesToTarget(files[0])
      }

      console.log(666666, this.editor.config)

      // // 配置粘贴文本的内容处理
      this.editor.config.pasteTextHandle = function (pasteStr) {
        // 对粘贴的文本进行处理，然后返回处理后的结果
        return pasteStr
      }

      this.$refs.editor.addEventListener('paste', evt => {
        this.customPaste(evt)
      })

      // this.editor.config.onchange = text => {
      //   console.log(22222222, this.editor.txt.getJSON())
      //   var jsonData = this.editor.txt.getJSON()
      //   // jsonData[1].children[0].attrs[0].value = 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2020-06-23%2F5ef1915dbeca9.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671950124&t=a557167f6d24a32a2731d3e50d2bfdcc'

      //   // setTimeout(() => {
      //   //   this.editor.txt.setJSON(jsonData)
      //   // }, 300)
      // }

      // 创建富文本编辑器
      this.editor.create()

      // 富文本编辑器创建之后，如果是编辑进来，则渲染内容
      this.editInfoRenderData()
    },
    customPaste (evt) {
      console.log(111111, evt)
      let html = evt.clipboardData.getData('text/html') // 获取粘贴的 html
      // let text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      const rtf = evt.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word、wps 复制粘贴）

      if (html && rtf) {
        // 列表缩进会超出边框，直接过滤掉
        html = html.replace(/text\-indent:\-(.*?)pt/gi, '')

        // 从html内容中查找粘贴内容中是否有图片元素，并返回img标签的属性src值的集合
        const imgSrcs = this.findAllImgSrcsFromHtml(html)

        // 如果有
        if (imgSrcs && Array.isArray(imgSrcs) && imgSrcs.length) {
          // 从rtf内容中查找图片数据
          const rtfImageData = this.extractImageDataFromRtf(rtf)

          // 如果找到
          if (rtfImageData.length) {
            // TODO：此处可以将图片上传到自己的服务器上

            // 执行替换：将html内容中的img标签的src替换成ref中的图片数据，如果上面上传了则为图片路径
            html = this.replaceImagesFileSourceWithInlineRepresentation(
              html,
              imgSrcs,
              rtfImageData
            )

            console.log(8888888, this.editor)
            // editor.dangerouslyInsertHtml(html)

            this.editor.txt.html(html)
            // this.editor.cmd.do(
            //   'dangerouslyInsertHtml',
            //   `
            //     ${html}
            //     `
            // )
          }
        }

        // 阻止默认的粘贴行为
        evt.preventDefault()
        return false
      }

      return true
    },
    findAllImgSrcsFromHtml (htmlData) {
      const imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
      const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src

      const arr = htmlData.match(imgReg) // 筛选出所有的img
      if (!arr || (Array.isArray(arr) && !arr.length)) {
        return false
      }

      const srcArr = []
      for (let i = 0; i < arr.length; i++) {
        const src = arr[i].match(srcReg)
        // 获取图片地址
        srcArr.push(src[1])
      }

      return srcArr
    },
    extractImageDataFromRtf (rtfData) {
      if (!rtfData) {
        return []
      }

      const regexPictureHeader =
        /{\\pict[\s\S]+?({\\\*\\blipuid\s?[\da-fA-F]+)[\s}]*/
      const regexPicture = new RegExp(
        '(?:(' + regexPictureHeader.source + '))([\\da-fA-F\\s]+)\\}',
        'g'
      )
      const images = rtfData.match(regexPicture)
      const result = []

      if (images) {
        for (const image of images) {
          let imageType = false

          if (image.includes('\\pngblip')) {
            imageType = 'image/png'
          } else if (image.includes('\\jpegblip')) {
            imageType = 'image/jpeg'
          }

          if (imageType) {
            result.push({
              hex: image
                .replace(regexPictureHeader, '')
                .replace(/[^\da-fA-F]/g, ''),
              type: imageType
            })
          }
        }
      }

      return result
    },
    replaceImagesFileSourceWithInlineRepresentation (
      htmlData,
      imageSrcs,
      imagesHexSources,
      isBase64Data = true
    ) {
      if (imageSrcs.length === imagesHexSources.length) {
        for (let i = 0; i < imageSrcs.length; i++) {
          const newSrc = isBase64Data
            ? `data:${
                imagesHexSources[i].type
              };base64,${this._convertHexToBase64(imagesHexSources[i].hex)}`
            : imagesHexSources[i]

          htmlData = htmlData.replace(imageSrcs[i], newSrc)
        }
      }

      return htmlData
    },
    //  十六进制转base64
    _convertHexToBase64 (hexString) {
      return window.btoa(
        hexString
          .match(/\w{2}/g)
          .map((char) => {
            return String.fromCharCode(parseInt(char, 16))
          })
          .join('')
      )
    },
    editInfoRenderData () {
      if (this.value) {
        this.setValue(this.value)
      }
    },
    /** 图片上传到华为云obs
     * @param {*} files  图片,数组
     * @param {number} size 文件最大限制，单位MB
     */
    async imageFilesToTarget (files, insert, size = 30) {
      files.forEach(file => {
        if (file.size > 1024 * 1024 * size) {
          this.$message.error(`上传文件大小不能超过${size}MB`)
          return
        }

        console.log(file)
        upload(file, (transferredAmount, totalAmount, totalSeconds) => {
          const uploadProgress = parseInt(transferredAmount * 100 / totalAmount, 10)
          console.log('已上传字节数', transferredAmount, ', 总字节数', totalAmount, ', 耗时', totalSeconds, ', 进度', uploadProgress)
        }).then(src => {
          this.editor.cmd.do(
            'insertHtml',
            '<img src="' + src + '" style="max-width:100%;"/>'
          )
        }).catch(err => {
          this.$message.error(err)
        })
      })
    },

    // 视屏一次性上传到华为云obs
    async videoFilesToTarget (file, insertVideoFn) {
      this.percentage = 0
      this.Show = true
      var callback = (transferredAmount, totalAmount, totalSeconds) => {
        // 获取上传平均速率
        this.AverageRate = `${(transferredAmount * 1.0 / totalSeconds / 1024 / 1024).toFixed(2)} MB/S`
        // 获取上传进度百分比
        this.percentage = (transferredAmount * 100.0 / totalAmount).toFixed(0)
        if (Number(this.percentage) === 100) {
          setTimeout(() => {
            this.Show = false
          }, 1000)
        }
      }

      upload(file, callback).then(src => {
        console.log('src', src)
        this.editor.cmd.do(
          'insertHtml',
          `
            <video width="100%" height="100%" controls="controls" poster="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20220124/1642992217472166241">
              <source src="${src}" type="video/mp4" />
            </video>
            `
        )
      }).catch(err => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss">
.editor {
  width: 1000px;
  .toolbar {
    border: 1px solid #ccc;
  }
  .el-progress-bar {
    width: 98%;
  }
  .text {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
  }
}
</style>
